export const PORTFOLIO_MANAGEMENT_PAGE_TABS = {
  active: 'constants.project.status.active', // translation.json
  backlog: 'constants.project.status.backlog',
  finished: 'constants.project.status.finished',
  archived: 'constants.project.status.archived',
};

export const STRATEGY_MAKING_PAGE_TABS = {
  'alignment-report': 'strategy_making.alignment_report.title',
  'tag-group-report': 'strategy_making.tag_group_report.title',
  'comparison-report': 'strategy_making.comparison_report.title',
  'alignment-builder': 'strategy_making.alignment_builder.title',
  'alignment-checker': 'strategy_making.alignment_checker.title',
  'objective-management': 'strategy_making.objective_management.title',
  'kpi-management': 'strategy_making.kpi_management.title',
  'x-matrix-playback': 'strategy_making.x_matrix_playback.title',
};

export const EXECUTION_REPORTS_PAGE_TABS = {
  'strategy-progress': 'execution_reports.strategy_progress.title',
  'kpi-report': 'execution_reports.kpi_report.title',
  'monthly-kpi-report': 'execution_reports.monthly_kpi_report.title',
  'comparison-report': 'execution_reports.comparison_report.title',
  'project-overview': 'execution_reports.project_overview.title',
  'progress-report': 'execution_reports.progress_report.title',
  'benefit-report': 'execution_reports.benefit_report.title',
  'project-involment': 'execution_reports.project_involvement.title',
  'portfolio-report': 'execution_reports.portfolio_report.title',
  'project-resources': 'execution_reports.project_resources.title',
  'alignment-overview': 'execution_reports.alignment_overview.title',
  'project-follow-up': 'execution_reports.project_follow_up.title',
  'risk-matrix': 'execution_reports.risk_matrix.title',
};

export const PROJECT_MANAGEMENT_PAGE_TABS = {
  task: 'tasks.tasks.title',
  'pm-reports': 'tasks.pm_reports.title',
  'project-overview': 'tasks.project_overview.title',
  'monthly-kpis': 'tasks.monthly_kpis.title',
  'objective-status': 'tasks.objective_status.title',
  backlog: 'tasks.backlog.title',
};

export const ALL_TASKS_PAGE_TABS = {
  'pm-reports': 'tasks.pm_reports.title',
  'project-overview': 'tasks.project_overview.title',
  'monthly-kpis': 'tasks.monthly_kpis.title',
  'objective-status': 'tasks.objective_status.title',
  'longterm-objective-status': 'tasks.Longterm_Objective.title',
  backlog: 'tasks.backlog.title',
};

export const USERS_PAGE_TABS: {[key: string]: string} = {
  'user-management': 'users.user_management.title',
  'organization-access-management':
    'users.organization_access_management.title',
  'add-new-user': 'users.add_new_user.title',
  'user-invitation': 'users.user_invitation_list.title',
  'upload-users': 'users.upload_users.title',
};

export const SETTINGS_PAGE_TABS = {
  organization: 'settings.organization.title',
  'organization-units': 'settings.organization_units.title',
  'tag-groups': 'settings.tag_groups.title',
  'project-setting': 'settings.project_settings.title',
  alerts: 'settings.alerts.title',
};

export const INTEGRATIONS_PAGE_TABS = {
  'access-tokens': 'integrations.access_tokens.title',
};
