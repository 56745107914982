import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {changeActiveOrgUnit, getOrgUnits} from 'redux/reducers/org-units';
import {TOrgUnitsInitState} from 'redux/types';

const initialState: TOrgUnitsInitState = {
  ORG_UNITS: [],
  loading: false,
  reloadRequired: false,
};

const slice = createSlice({
  name: 'org_units',
  initialState,
  reducers: {
    toggleOrgChangeLoading: (
      state: TOrgUnitsInitState,
      action: PayloadAction<any>
    ) => {
      state.loading = action.payload;
    },
    setReloadRequired: (state: TOrgUnitsInitState) => {
      state.reloadRequired = true;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getOrgUnits.pending, state => {
        state.loading = true;
      })
      .addCase(getOrgUnits.fulfilled, (state, action: PayloadAction<any>) => {
        state.ORG_UNITS = action.payload.data.data;
        state.reloadRequired = false;
        state.loading = false;
      })
      .addCase(changeActiveOrgUnit.pending, () => {})
      .addCase(changeActiveOrgUnit.fulfilled, () => {});
  },
});

export default slice.reducer;
export const {actions} = slice;
export const {toggleOrgChangeLoading, setReloadRequired} = slice.actions;
